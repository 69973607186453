<template>
  <Toast />
  <Dialog
    v-model:visible="activarModal"
    :style="{ width: '900px' }"
    :header="'DETALLE DE PRODUCTO:' + ' ' + (data_producto.descripcion || '-')"
    :modal="true"
    :maximizable="true"
    @hide="closeModal"
    class="p-fluid"
  >
    <div class="col-12">
      <div class="p-fluid formgrid grid">
        <div class="field col-12 md:col-2">
          <span
            ><strong>C&Oacute;DIGO INTERNO:</strong>
            <span class="p-invalid" v-if="facturar_producto == 1">*</span></span
          >
          <InputText
            placeholder="Código Interno"
            v-model="data_producto.codigo_interno"
          ></InputText>
          <small class="p-invalid" v-if="errors.codigo_interno">{{
            errors.codigo_interno[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>C&Oacute;DIGO DE BARRAS: </strong> </span>
          <InputText
            placeholder="Código de Barras"
            v-model="data_producto.codigo_barra"
          />
          <small class="p-invalid" v-if="errors.codigo_barra">{{
            errors.codigo_barra[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-8">
          <span
            ><strong>NOMBRE DE PRUDUCTO | DESCRIPCI&Oacute;N: </strong>
            <span class="p-invalid">*</span></span
          >
          <Textarea
            placeholder="Descripción/Nombre del Producto"
            autofocus
            :rows="1"
            v-model="data_producto.descripcion"
            autoResize
          />
          <small class="p-invalid" v-if="errors.descripcion">{{
            errors.descripcion[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>LABORATORIO: </strong>
            <span class="p-invalid">* </span></span
          >
          <span
            class="ml-1"
            v-if="data_producto.fabricas != null"
            v-tooltip.top="'PROVEEDOR'"
          >
            {{ data_producto.fabricas.nombre_proveedor }}
          </span>

          <div class="p-inputgroup">
            <Dropdown
              v-model="laboratorioSelect"
              :options="laboratorios"
              optionLabel="nombre"
              :filter="true"
              placeholder="Seleccione un laboratorio"
            >
              <template #option="slotProps">
                <div class="p-dropdown-car-option">
                  <span
                    >{{ slotProps.option.nombre }} -
                    {{ slotProps.option.nombre_proveedor }}
                  </span>
                </div>
              </template>
            </Dropdown>
            <Button
              class="p-button-info"
              v-if="'Laboratorio Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarLaboratorioModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.fabrica_id">{{
            errors.fabrica_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span
            ><strong>UNIDAD DE MEDIDA: </strong>
            <span class="p-invalid">*</span></span
          >
          <div class="p-inputgroup">
            <Dropdown
              v-model="unidadSelect"
              :options="unidadesmedidas"
              :filter="true"
              optionLabel="nombre"
              placeholder="Seleccione una unidade medida"
            >
              <template #option="slotProps">
                <span> {{ slotProps.option.nombre }} </span>
              </template>
            </Dropdown>
            <Button
              class="p-button-info"
              v-if="'Unidad de Medida Crear' in auth.user.permissions"
              icon="pi pi-plus"
              @click="activarUnidadMedidaModal"
            />
          </div>
          <small class="p-invalid" v-if="errors.unidadmedida_id">{{
            errors.unidadmedida_id[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Iacute;NIMO: </strong></span>
          <InputNumber
            placeholder="0"
            :inputClass="'text-right'"
            v-model="data_producto.stock_minimo"
            :min="0"
          />
          <small class="p-invalid" v-if="errors.stock_minimo">{{
            errors.stock_minimo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>STOCK M&Aacute;XIMO: </strong></span>
          <InputNumber
            v-model="data_producto.stock_maximo"
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
          />
          <small class="p-invalid" v-if="errors.stock_maximo">{{
            errors.stock_maximo[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-4">
          <span><strong>SUCURSAL: </strong></span>
          <Dropdown
            v-model="sucursalSelect"
            :options="sucursales"
            optionLabel="nombre"
            @change="changeSucursal"
          />
        </div>
        <div class="field col-12 md:col-2 fondo mb-1">
          <span><strong>STOCK ACTUAL: </strong></span>
          <InputNumber
            v-model="data_producto.stock_actual"
            placeholder="0"
            :inputClass="'text-right'"
            :min="0"
            locale="de-ED"
            :readonly="!actualizar_precio_compra"
          />
        </div>
        <div class="field col-12 md:col-2">
          <span
            ><strong
              >P.U COMPRA:
              <Checkbox
                :readonly="!('Producto Actualizar' in auth.user.permissions)"
                v-model="actualizar_precio_compra"
                :binary="true"
                v-tooltip.top="
                  'Actualizar Stock y Precio de Compra en productos existentes'
                " /></strong
          ></span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.precio_compra"
            :inputClass="'text-right'"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :readonly="!actualizar_precio_compra"
            v-tooltip.top="'Precio Unitario de Compra'"
          />
        </div>
        <div
          class="field col-12 md:col-4"
          v-if="actualizar_precio_compra && sin_motivo_actualizacion == false"
        >
          <span><strong>MOTIVO ACTUALIZACIÓN: </strong></span>
          <Textarea
            v-model="descripcion_kardex"
            :rows="1"
            placeholder="Ingrese el motivo de la actualización"
            autoResize
          />
        </div>
        <div class="field col-12 md:col-2">
          <span
            ><strong
              >P.U VENTA:
              <Checkbox
                v-if="'Producto Actualizar' in auth.user.permissions"
                :readonly="!data_producto.id"
                v-model="actualizar_precio_venta"
                :binary="true"
                class="mr-1"
                v-tooltip.top="
                  'Actualizar precio de venta en productos existentes'
                "
              /> </strong
          ></span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.precio_sugerido"
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :readonly="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.precio_sugerido">{{
            errors.precio_sugerido[0]
          }}</small>
        </div>
        <div class="field col-12 md:col-2">
          <span><strong>ESTADO: </strong></span>
          <Dropdown
            v-model="data_producto.estado"
            :disabled="!data_producto.id"
            :options="estados"
            optionLabel="label"
            placeholder="Seleccione un estado"
          >
            <template #value="slotProps">
              <span
                :class="'product-badge ' + claseEstado[slotProps.value.value]"
              >
                {{ slotProps.value.label }}
              </span>
            </template>
          </Dropdown>
        </div>
        <div class="field col-12 md:col-4" v-show="maneja_mas_de_un_precio">
          <span v-if="pv2_pv3_modifados"><strong>P.U PROVINCIAL:</strong></span>
          <span v-else><strong>PV2:</strong></span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.pv2"
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv2">{{ errors.pv2[0] }}</small>
        </div>
        <div class="field col-12 md:col-4" v-show="maneja_mas_de_un_precio">
          <span v-if="pv2_pv3_modifados"><strong>P.U LINAME:</strong></span>
          <span v-else><strong>PV3:</strong></span>
          <InputNumber
            placeholder="0.00"
            v-model="data_producto.pv3"
            :inputClass="'text-right'"
            :min="0"
            :minFractionDigits="2"
            :maxFractionDigits="2"
            locale="de-ED"
            :disabled="data_producto.id && !actualizar_precio_venta"
          />
          <small class="p-invalid" v-if="errors.pv3">{{ errors.pv3[0] }}</small>
        </div>
        <div
          :class="
            actualizar_precio_compra
              ? 'field col-12 md:col-3'
              : 'field col-12 md:col-4'
          "
        >
          <span><strong>PRINCIPIO ACTIVO: </strong></span>
          <Textarea
            v-model="data_producto.caso_uso"
            :rows="1"
            placeholder="Ingrese el principio activo"
            autoResize
          />
        </div>
        <div
          :class="
            actualizar_precio_compra && sin_motivo_actualizacion == false
              ? 'field col-12 md:col-3'
              : 'field col-12 md:col-4'
          "
        >
          <span><strong>ACCI&Oacute;N FARMACOL&Oacute;GICA: </strong></span>
          <Textarea
            v-model="data_producto.accion_farmacologica"
            :rows="1"
            placeholder="Ingrese la acción farmacológica"
            autoResize
          />
        </div>
        <div
          :class="
            actualizar_precio_compra
              ? 'field col-12 md:col-2'
              : 'field col-12 md:col-4'
          "
        >
          <span><strong>UBICACI&Oacute;N: </strong></span>
          <Textarea
            v-model="data_producto.ubicacion"
            :rows="1"
            placeholder="Ingrese la ubicación del producto"
            autoResize
          />
        </div>
        <div class="grid col-12">
          <div
            class="field col-12 md:col-2"
            v-show="'Producto Homologar' in auth.user.permissions"
          >
            <span class="mr-2"><strong>HOMOLOGAR: </strong></span>
            <Checkbox
              id="facturado"
              v-model="facturar_producto"
              :binary="true"
              v-tooltip.top="
                data_producto.facturado == 1
                  ? 'Producto Homologado'
                  : 'Homologar Producto'
              "
              :disabled="data_producto.facturado == 1"
            />
          </div>
          <div
            class="field col-12 md:col-10"
            v-show="'Producto Homologar' in auth.user.permissions"
          >
            <span><strong>SELECCIONE C&Oacute;DIGO SIAT: </strong></span>
            <Dropdown
              v-model="codigoSINSelect"
              :options="codigossin"
              :filter="true"
              optionLabel="descripcionProducto"
              placeholder="Seleccione un codigo siat"
            >
              <template #option="slotProps">
                <span
                  class="font-weight-bold"
                  style="font-size: 1em"
                  v-tooltip.top="slotProps.option.descripcionProducto"
                >
                  {{
                    slotProps.option.descripcionProducto.length > 80
                      ? slotProps.option.descripcionProducto.substring(0, 80) +
                        "..."
                      : slotProps.option.descripcionProducto
                  }}
                </span>
              </template>
            </Dropdown>
          </div>
          <div class="field col-12 md:col-2" v-show="false">
            <span><strong>TIPO:</strong></span>
            <Dropdown
              v-model="data_producto.tipo_catalogo"
              :options="tipos_catalogo"
              optionLabel="label"
              optionValue="value"
            />
          </div>
        </div>
      </div>
    </div>
    <template #footer>
      <div class="field">
        <span class="flex md:justify-content-start"
          ><strong>CAMPOS REQUERIDOS: </strong>
          <span class="p-invalid">*</span></span
        >
      </div>
      <Button
        label="CANCELAR"
        icon="pi pi-times"
        class="p-button-secondary p-button-lg"
        @click="closeModal"
      />
      <Button
        :label="data_producto.id == null ? 'GUARDAR' : 'ACTUALIZAR'"
        icon="pi pi-save"
        class="p-button-info p-button-lg"
        @click="guardarProducto"
        :loading="enviado"
      />
    </template>
  </Dialog>
  <UnidadMedidaCreate
    :show="UnidadMedidaModal"
    :unidadmedida="unidadMedida"
    @closeModal="cerrarModalUnidadMedida"
    @actualizarListado="cargarUnidadesMedidas"
  ></UnidadMedidaCreate>
  <LaboratorioCreate
    :mostrar="LaboratorioModal"
    :laboratorio="laboratorio"
    @closeModal="cerrarModalLaboratorio"
    @actualizarListadoLaboratorio="cargarLaboratorios"
  ></LaboratorioCreate>
</template>

<script>
import UnidadMedidaCreate from "@/module/unidadmedida/UnidadMedidaCreate.vue";
import LaboratorioCreate from "@/module/laboratorios/LaboratorioCreate.vue";
import ProductService from "@/service/ProductService";
import LaboratorioService from "@/service/LaboratorioService";
import UnidadmedidaService from "@/service/UnidadmedidaService";
import { useAuth } from "@/stores";
import SucursalService from "@/service/SucursalService";

export default {
  components: {
    UnidadMedidaCreate,
    LaboratorioCreate,
  },
  emits: ["closeModal", "actualizarListadoProductos"],
  props: {
    mostrar: {
      type: Boolean,
      default: false,
    },
    producto: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  productoService: null,
  laboratorioService: null,
  unidadmedidaService: null,
  sucursalService: null,
  auth: null,
  data() {
    return {
      activarModal: this.mostrar,
      data_producto: this.producto,
      laboratorios: {},
      unidadesmedidas: {},
      laboratorioSelect: {},
      unidadSelect: {},
      UnidadMedidaModal: false,
      LaboratorioModal: false,
      laboratorio: { estado: { label: "Activo", value: 1 } },
      unidadMedida: { estado: { label: "Activo", value: 1 } },
      estados: [
        { label: "Inactivo", value: 0 },
        { label: "Activo", value: 1 },
      ],
      enviado: false,
      errors: {},
      claseEstado: ["status-outofstock", "status-instock"],
      actualizar_precio_compra: 0,
      actualizar_precio_venta: 0,
      sucursalSelect: null,
      sucursales: [],
      //si data_producto.facturado == 1, entonces facturar_producto = 1
      facturar_producto: 0,
      codigoSINSelect: { label: "Seleccione un codigo siat", value: null },
      unidadMedidaSelect: {},
      codigossin: [],
      tipos_catalogo: [
        { label: "PRODUCTO", value: 1 },
        { label: "SERVICIO", value: 2 },
      ],
      descripcion_kardex: "",
      parametros_sistema: {},
      pv2_pv3_modifados: false,
      maneja_mas_de_un_precio: false,
      sin_motivo_actualizacion: false,
    };
  },
  created() {
    this.auth = useAuth();
    this.productoService = new ProductService();
    this.laboratorioService = new LaboratorioService();
    this.unidadmedidaService = new UnidadmedidaService();
    this.sucursalService = new SucursalService();
  },
  mounted() {
    this.getDatosNecesariosProducto();
  },
  methods: {
    getDatosNecesariosProducto() {
      this.productoService.getDatosNecesariosProducto().then((response) => {
        this.laboratorios = response.datos.fabricas ?? [];
        this.unidadesmedidas = response.datos.unidadmedida ?? [];
        this.codigossin = response.datos.codigossin ?? [];
        this.sucursales = response.datos.sucursales || [];
        this.sucursalSelect = this.sucursales[0].id || null;
        this.parametros_sistema = response.datos.parametros_sistema ?? {};
        if (this.parametros_sistema) {
          //recorro y busco en el campo nombre pv2_pv3_modifados
          this.parametros_sistema.forEach((element) => {
            if (element.nombre == "pv2_pv3_modifados") {
              this.pv2_pv3_modifados = true;
            }
            if (element.nombre == "maneja_mas_de_un_precio") {
              this.maneja_mas_de_un_precio = true;
            }

            if (element.nombre == "sin_motivo_actualizacion") {
              this.sin_motivo_actualizacion = true;
            }
          });
        }
      });
    },
    cargarCodigosSIN() {
      this.productoService
        .getCodigoSIN()
        .then((response) => {
          this.codigossin = response.codigossin || [];
          if (response.status == 200) {
            this.$toast.add({
              severity: "success",
              summary: "Exito",
              detail: response.message,
              life: 3000,
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarSucursales() {
      this.sucursalService.getSucursalesAll().then((response) => {
        this.sucursales = response;
        this.sucursalSelect = this.sucursales[0] ?? null;
      });
    },
    cerrarModalLaboratorio() {
      this.LaboratorioModal = false;
      this.laboratorio = {};
    },
    cerrarModalUnidadMedida() {
      this.UnidadMedidaModal = false;
      this.unidadMedida = {};
    },
    activarLaboratorioModal() {
      this.LaboratorioModal = true;
      this.laboratorio = {
        estado: { label: "Activo", value: 1 },
      };
    },
    activarUnidadMedidaModal() {
      this.UnidadMedidaModal = true;
      this.unidadMedida = {
        estado: { label: "Activo", value: 1 },
      };
    },
    cargarLaboratorios(fabrica = null) {
      this.laboratorioService
        .getAllLaboratorios()
        .then((response) => {
          this.laboratorios = response.fabricas;
          if (fabrica) {
            this.laboratorios.forEach((element) => {
              if (element.id == fabrica.id) {
                this.laboratorioSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    cargarUnidadesMedidas(unidadmedida = null) {
      this.unidadmedidaService
        .getUnidadmedidasAll()
        .then((response) => {
          this.unidadesmedidas = response;
          if (unidadmedida) {
            this.unidadesmedidas.forEach((element) => {
              if (element.id == unidadmedida.id) {
                this.unidadSelect = element;
              }
            });
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
    closeModal() {
      this.errors = {};
      this.laboratorioSelect = {};
      this.unidadSelect = {};
      this.activarModal = false;
      this.$emit("closeModal");
      this.actualizar_precio_compra = 0;
      this.sucursalSelect = this.sucursales[0];
      this.actualizar_precio_compra = 0;
      this.actualizar_precio_venta = 0;
      this.facturar_producto = 0;
      this.codigoSINSelect = {};
      this.unidadMedidaSelect = {};
      this.enviado = false;
      this.descripcion_kardex = "";
    },
    guardarProducto() {
      if (
        this.facturar_producto == 1 &&
        this.data_producto.codigo_interno == null
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar un codigo interno para el producto",
          life: 3000,
        });
        return;
      }
      if (
        this.actualizar_precio_compra == 1 &&
        this.descripcion_kardex == "" &&
        this.sin_motivo_actualizacion == false
      ) {
        this.$toast.add({
          severity: "error",
          summary: "Error",
          detail: "Debe ingresar el motivo de la actualización",
          life: 3000,
        });
        return;
      }
      this.errors = {};
      this.enviado = true;
      if (this.data_producto.id) {
        //Actualizar
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
          /* facturar_producto: this.facturar_producto ? 1 : 0, */
          facturar_producto: this.data_producto.facturado
            ? 1
            : this.facturar_producto
            ? 1
            : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
          descripcion_kardex: this.descripcion_kardex ?? "",
        };

        this.productoService.actualizarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: response.mensaje,
              life: 3000,
            });
          } else {
            this.errors = {};

            this.$toast.add({
              severity: "success",
              summary: "Actualizado!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      } else {
        //Registrar nuevo producto
        if (this.laboratorioSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar un laboratorio",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        if (this.unidadSelect == null) {
          this.$toast.add({
            severity: "error",
            summary: "Error!",
            detail: "Debe seleccionar una unidad de medida",
            life: 3000,
          });
          this.enviado = false;
          return;
        }
        let tmp = {
          ...this.data_producto,
          estado: this.data_producto.estado.value,
          fabrica_id: this.laboratorioSelect.id,
          unidadmedida_id: this.unidadSelect.id,
          facturar_producto: this.facturar_producto ? 1 : 0,
          unidad_medida_sin: this.unidadMedidaSelect.id,
          codigo_siat:
            this.codigoSINSelect != null
              ? this.codigoSINSelect.codigoProducto
              : null,
          actualizar_precio_compra: this.actualizar_precio_compra ? 1 : 0,
          actualizar_precio_venta: this.actualizar_precio_venta ? 1 : 0,
          sucursal_id: this.sucursalSelect.id ?? null,
          stock_actual: this.data_producto.stock_actual ?? 0,
        };

        this.productoService.registrarProducto(tmp).then((response) => {
          if (Object.prototype.hasOwnProperty.call(response, "errors")) {
            this.errors = response.errors;
            this.$toast.add({
              severity: "error",
              summary: "Error!",
              detail: response.mensaje,
              life: 3000,
            });
          } else {
            this.errors = {};
            this.$toast.add({
              severity: "success",
              summary: "Exito!",
              detail: response.mensaje,
              life: 3000,
            });
            this.$emit("actualizarListadoProductos");
            this.$emit("closeModal");
          }
          this.enviado = false;
        });
      }
    },
    findIndex(id) {
      let index = -1;
      for (let i = 0; i < this.productos.length; i++) {
        if (this.productos[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    textoEstado(estado) {
      return estado == 1 ? "Activo" : "Inactivo";
    },
    changeSucursal() {
      let datos = {
        producto_id: this.data_producto.id,
        sucursal_id: this.sucursalSelect.id,
      };
      this.productoService.getProductoSucursal(datos).then((response) => {
        if (response.stock.length == 0) {
          this.data_producto.stock_actual = 0;
          this.data_producto.precio_compra = 0;
          this.data_producto.precio_sugerido =
            this.data_producto.precio_sugerido ?? 0;
          this.data_producto.ubicacion = this.data_producto.ubicacion ?? "";
          this.$toast.add({
            severity: "info",
            summary: "Cambio de sucursal!",
            detail: "Se cargaron los datos de la sucursal seleccionada",
            life: 3000,
          });
          return;
        }
        this.data_producto.stock_actual = parseFloat(
          response.stock[0].cantidad ?? 0
        );
        this.data_producto.precio_compra = parseFloat(
          response.stock[0].precio_compra ?? 0
        );
        this.data_producto.precio_sugerido = parseFloat(
          response.stock[0].precio_venta ?? 0
        );
        this.data_producto.pv2 = parseFloat(response.stock[0].pv2 ?? 0);
        this.data_producto.pv3 = parseFloat(response.stock[0].pv3 ?? 0);
        this.data_producto.pv4 = parseFloat(response.stock[0].pv4 ?? 0);
        this.data_producto.pv5 = parseFloat(response.stock[0].pv5 ?? 0);
        this.data_producto.ubicacion = response.stock[0].ubicacion ?? "";
        this.$toast.add({
          severity: "info",
          summary: "Cambio de sucursal!",
          detail: "Se cargaron los datos de la sucursal seleccionada",
          life: 3000,
        });
      });
    },
  },
  watch: {
    mostrar(newVal) {
      this.activarModal = newVal;
    },
    producto(newVal) {
      this.data_producto = newVal;
      // cuando es nuevo producto que cargue el primer elemento de cada lista que tenga el id 1
      if (this.data_producto.id == null) {
        this.laboratorioSelect = this.laboratorios.find(
          (fabrica) => fabrica.id == 1 ?? null
        );
        this.unidadSelect = this.unidadesmedidas.find(
          (unidad) => unidad.id == 1 ?? null
        );
        this.sucursalSelect = this.sucursales.find(
          (sucursal) => sucursal.id == 1 ?? null
        );
        return;
      }
      this.laboratorioSelect = this.laboratorios.find(
        (fabrica) => fabrica.id == this.data_producto.fabrica_id ?? null
      );

      this.unidadSelect = this.unidadesmedidas.find(
        (unidad) => unidad.id == this.data_producto.unidadmedida_id ?? null
      );
      this.codigoSINSelect = this.codigossin.find(
        (codigo) => codigo.codigoProducto == this.data_producto.codigo_siat
      );
      this.sucursalSelect = this.sucursales.find(
        (sucursal) => sucursal.id == 1 ?? null
      );
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/demo/badges.scss";

.p-invalid {
  color: red;
}
</style>
